import React, { useEffect, useRef, useState } from "react";
import BreadCrumbs from "../../ReuseComponents/BreadCrumbs/BreadCrumbs";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import QRCode from "react-qr-code";
import CustomizedButtons from "../../ReuseComponents/Buttons/ColorButton";
import Popup from "../../ReuseComponents/Popup/Popup";
import { useNavigate } from "react-router-dom";
import Controller from "../../../Controller/ApiController";
import Header from "../../ReuseComponents/Header/Header";
import { useSelector } from "react-redux";
import {
  BasicMenuItem,
  BasicSelect,
} from "../../ReuseComponents/Select/BasicSelect";
import { ContainedButton } from "../../ReuseComponents/Button/BasicButton";
import dayjs from "dayjs";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  "&:focus": {
    outline: "none",
  },
  padding: "20px",
  paddingTop: "5px",
  height: "390px",
  width: "95%",
  marginTop: "10%",
};

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function QRGenerator() {
  let doctor_id = localStorage.getItem("DoctorUid");
  const [value, setValue] = useState("");
  const [back, setBack] = useState("#ffffff");
  const [fore, setFore] = useState("#000000");
  const [size, setSize] = useState(512);
  const navigate = useNavigate();
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [userDataList, setUserDataList] = useState("");
  const [urlLink, setUrlLink] = useState("https://dev.qglider.com/");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserFullName, setSelectedUserFullName] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");
  const [userType, setUerType] = useState("");
  const [generated, setGenerated] = useState(false);
  const userDetails = useSelector((state) => state?.userDetails?.data || {});
  const [base64QR, setBase64QR] = useState("");
  const [slots, setSlots] = useState("");
  const [lineProgressLoading, setLineProgressLoading] = useState(false);
  const qrRef = useRef();
  const [loaderOpen, setLoaderOpen] = useState(false);
  const handleLoaderOpen = () => {
    setLoaderOpen(true);
  };

  const handleLoaderClose = () => {
    setLoaderOpen(false);
  };

  const navigatePrevious = () => {
    if (generated) {
      setGenerated(false);
      setValue("");
    } else {
      navigate(-1);
    }
  };

  const generateQR = async () => {
    setGenerated(true);
    let query = "";
    let fetchUser = users.find((val) => val.user_uid == selectedUser);
    let parseFullNameValue = fetchUser?.full_name || "";
    if (selectedDuration) {
      query = "?duration=" + selectedDuration;
      parseFullNameValue += ` (${parseScanDuration(selectedDuration)})`;
    }
    setSelectedUserFullName(parseFullNameValue);
    setValue(urlLink + "patientqrscan/" + selectedUser + query);
  };

  const userRequest = async () => {
    try {
      if (!userDetails.admin_uid) {
        return;
      }

      let queryParams = {
        "filter.user_type": "DOCTOR,SCAN",
        "filter.doctor_uid": userDetails.admin_uid,
      };

      if (["DOCTOR", "SCAN"].includes(userDetails.user_type)) {
        queryParams = {
          ...queryParams,
          "filter.user_uid": userDetails?.user_uid || "",
        };
      } else {
        queryParams = {
          ...queryParams,
          "filter.user_uid": userDetails?.user_uid || "",
          "filter.user_uid_type": "MAPED_DOCTOR",
        };
      }

      const response = await Controller.ApiController(
        "GET",
        "/manage_users",
        "",
        queryParams
      );

      if (response.type == "success") {
        setUsers(response?.data || []);
      }
    } catch (e) {
      console.log("User Request: ", e.message);
    }
  };

  const handleClinicQR = async (qrLink) => {
    try {
      let queryParams = {
        image_link: qrLink,
      };
      handleLoaderOpen();
      const response = await Controller.ApiController(
        "GET",
        "/qr_image/base64",
        "",
        queryParams
      );
      handleLoaderClose();
      if (response.type == "success") {
        setBase64QR(response?.data || []);
      }
    } catch (e) {
      handleLoaderClose();
      console.log("User Request: ", e.message);
    }
  };

  useEffect(() => {
    if (userDetails) {
      userRequest();
    }
    if (userDetails?.clinic_qr) {
      handleClinicQR(userDetails?.clinic_qr);
    }
  }, [userDetails]);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  const getSlotTime = async (userUID) => {
    let reqParams = {
      "filter.current_date":
        dayjs(new Date()).startOf("day").toISOString() || "",
    };
    try {
      setLineProgressLoading(true);
      let res = await Controller.ApiController(
        "GET",
        `/get_slot/${userUID}`,
        "?",
        reqParams
      );
      setLineProgressLoading(false);

      if (res && res.data && Array.isArray(res.data)) {
        let slotTimeListdata = [];

        slotTimeListdata = res?.data;
        setSlots(slotTimeListdata || []);
      } else if (res.message) {
        setLineProgressLoading(false);
        setSlots([]);
      }
    } catch (error) {
      // handleLoaderClose();
      setLineProgressLoading(false);
      console.log(error, "slotTimedata");
    }
  };

  const handleUserChange = (e) => {
    const { value } = e.target;
    setSelectedUser(value);
    let fetchUser = users.find((val) => val.user_uid == value);

    if (fetchUser.user_type == "SCAN") {
      getSlotTime(value);
    }
    setUerType(fetchUser.user_type);
    setSelectedDuration("");
  };

  const parseScanDuration = (value) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    let parseValue = "";

    if (parseInt(formattedHours) > 0) {
      parseValue += parseInt(formattedHours) + " Hr ";
    }
    if (parseInt(formattedMinutes) > 0) {
      parseValue += parseInt(formattedMinutes) + " Min";
    }
    return parseValue;
  };

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const downloadImage = async (imageUrl) => {
    try {
      const link = document.createElement("a");
      link.href = base64QR;
      link.download = `${
        userDetails?.hospital_or_clinic_name || "Clinic QR"
      }.png`;
      link.click();
    } catch (error) {
      console.error("Failed to download image", error);
    }
  };

  const handleDownload = () => {
    const svg = qrRef.current.querySelector("svg");
    const svgData = new XMLSerializer().serializeToString(svg);
    let fileName = "";
    if (userDetails?.hospital_or_clinic_name) {
      fileName = userDetails?.hospital_or_clinic_name || "";
    }
    let selectedDoctor = users.find((val) => val.user_uid == selectedUser);

    if (selectedDoctor) {
      if (fileName) {
        fileName += " - ";
      }
      fileName += selectedDoctor?.full_name || "";
    }

    if (userType == "SCAN") {
      if (fileName) {
        fileName += " - ";
      }
      fileName += parseScanDuration(selectedDuration) || "";
    }

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
  
    const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    const url = URL.createObjectURL(svgBlob);
  
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
  
      // Convert canvas to PNG and trigger download
      canvas.toBlob((blob) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${fileName || "Doctor QR"}.png`;
        downloadLink.click();
  
        // Cleanup
        URL.revokeObjectURL(url);
      }, "image/png");
    };
  
    img.src = url;
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
      </Grid>
      {!isMd && (
        <BreadCrumbs
          crumbs={["Dashboard", "Generate QR Code"]}
          paths={["/dashboard", "/generateQR"]}
        />
      )}

      {isMd ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
            mb: 2,
          }}
        >
          <Box
            sx={{
              ...style,
              maxWidth: base64QR ? "750px" : "400px",
            }}
          >
            <Box sx={{ position: "relative" }} mb={4}>
              {/* <Tooltip title="Go Back" placement="top"> */}
              <IconButton
                onClick={() => navigate(-1)}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 5,
                  backgroundColor: "red",
                  "&:hover": {
                    backgroundColor: "red",
                  },
                  padding: "3px",
                }}
              >
                <CloseIcon sx={{ color: "#fff", fontSize: "18px" }} />
              </IconButton>
              {/* </Tooltip> */}
            </Box>
            <Grid container>
              {base64QR && (
                <>
                  <Grid item xs={12} md={5.5}>
                    <Typography
                      fontSize={"20px"}
                      mb={3}
                      fontFamily={"Poppins"}
                      color={"#243665"}
                      fontWeight={700}
                    >
                      Clinic QR Code
                    </Typography>
                    <Box
                      component={"img"}
                      sx={{ height: { xs: 150, md: 205 } }}
                      src={base64QR}
                    />
                    <Box sx={{ marginTop: "25px" }}>
                      <ContainedButton
                        sx={{
                          width: "85px",
                        }}
                        onClick={downloadImage}
                      >
                        Download
                      </ContainedButton>
                    </Box>
                  </Grid>
                  <Grid item md={0.5}>
                    <Divider orientation="vertical" sx={{ height: "325px" }} />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={base64QR ? 6 : 12}>
                <Box
                  sx={{
                    textAlign: "center",
                    paddingLeft: "1px",
                  }}
                >
                  <Typography
                    fontSize={"20px"}
                    sx={{ mb: 3, mt: { xs: 2, md: 0 } }}
                    fontFamily={"Poppins"}
                    color={"#243665"}
                    fontWeight={700}
                  >
                    {generated ? "Scan QR Code" : "Generate a QR Code here"}
                  </Typography>
                  {value ? (
                    <>
                      <Box ref={qrRef}>
                        <QRCode
                          title="QGLIDER"
                          value={value}
                          bgColor={back}
                          fgColor={fore}
                          size={isMd ? 204 : 150}
                          level="H"
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          paddingLeft: "10px",
                          textWrap: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {" "}
                        <span style={{ fontWeight: "550" }}>
                          {userType == "SCAN" ? "Scan" : "Doctor"} Name:{" "}
                        </span>{" "}
                        <Tooltip
                          title={
                            selectedUserFullName?.length > 20 &&
                            selectedUserFullName
                          }
                          placement="top"
                        >
                          {selectedUserFullName}
                        </Tooltip>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Box>
                        <BasicSelect
                          value={selectedUser || "default"}
                          onChange={handleUserChange}
                          sx={{
                            textAlign: "left",
                            width: "220px",
                            mt: { xs: 0, md: 5 },
                          }}
                        >
                          <BasicMenuItem
                            value="default"
                            style={{ display: "none" }}
                          >
                            <span style={{ color: "#bbb7b7" }}>
                              Select Doctor or Scan
                            </span>
                          </BasicMenuItem>
                          {Array.isArray(users) && users.length > 0 ? (
                            users.map((val, i) => (
                              <BasicMenuItem key={i} value={val.user_uid}>
                                {val.full_name}
                              </BasicMenuItem>
                            ))
                          ) : (
                            <BasicMenuItem disabled>
                              No Doctors Available
                            </BasicMenuItem>
                          )}
                        </BasicSelect>
                      </Box>
                      {userType == "SCAN" && (
                        <BasicSelect
                          value={selectedDuration || "default"}
                          onChange={(e) => setSelectedDuration(e.target.value)}
                          sx={{
                            textAlign: "left",
                            width: "220px",
                            marginTop: "20px",
                          }}
                        >
                          <BasicMenuItem
                            value="default"
                            style={{ display: "none" }}
                          >
                            <span style={{ color: "#bbb7b7" }}>
                              Select Duration
                            </span>
                          </BasicMenuItem>
                          {lineProgressLoading ? (
                            <Box sx={{ width: "100%" }}>
                              <LinearProgress />
                            </Box>
                          ) : Array.isArray(slots) && slots.length > 0 ? (
                            slots.map((val, i) => (
                              <BasicMenuItem key={i} value={val}>
                                {parseScanDuration(val)}
                              </BasicMenuItem>
                            ))
                          ) : (
                            <BasicMenuItem disabled>
                              No Doctors Available
                            </BasicMenuItem>
                          )}
                        </BasicSelect>
                      )}
                    </>
                  )}

                  {generated ? (
                    <Box sx={{ textAlign: "center", marginTop: "5px" }}>
                      <ContainedButton
                        sx={{
                          backgroundColor: "#243665",
                          "&:hover": {
                            backgroundColor: "#243665",
                          },
                          width: "85px",
                          mr: 2,
                        }}
                        onClick={navigatePrevious}
                      >
                        Back
                      </ContainedButton>
                      <ContainedButton
                        sx={{
                          width: "85px",
                        }}
                        onClick={handleDownload}
                      >
                        Download
                      </ContainedButton>
                    </Box>
                  ) : (
                    <Box sx={{ textAlign: "center", marginTop: "30px" }}>
                      <ContainedButton
                        disabled={
                          !selectedUser ||
                          (userType == "SCAN" && !selectedDuration)
                        }
                        onClick={generateQR}
                      >
                        Generate QR Code
                      </ContainedButton>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Clinic QR" sx={{ textTransform: "none" }} />
            <Tab label="Generate QR" sx={{ textTransform: "none" }} />
          </Tabs>

          <Box display={tabValue != 0 && "none"} mt={5} textAlign={"center"}>
            <Box component={"img"} sx={{ height: 204 }} src={base64QR} />
            <Box sx={{ textAlign: "center", marginTop: "20px" }}>
              <ContainedButton
                sx={{
                  width: "85px",
                }}
                onClick={downloadImage}
              >
                Download
              </ContainedButton>
            </Box>
          </Box>
          <Box display={tabValue != 1 && "none"} mt={5} textAlign={"center"}>
            {value ? (
              <>
                <Box ref={qrRef}>
                  <QRCode
                    title="QGLIDER"
                    value={value}
                    bgColor={back}
                    fgColor={fore}
                    size={204}
                    level="H"
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  <span style={{ fontWeight: "550" }}>
                    {userType == "SCAN" ? "Scan" : "Doctor"} Name:
                  </span>{" "}
                  {selectedUserFullName}
                </Typography>
              </>
            ) : (
              <>
                <Box>
                  <BasicSelect
                    value={selectedUser || "default"}
                    onChange={handleUserChange}
                    sx={{
                      textAlign: "left",
                      width: "220px",
                      mt: { xs: 0, md: 5 },
                    }}
                  >
                    <BasicMenuItem value="default" style={{ display: "none" }}>
                      <span style={{ color: "#bbb7b7" }}>
                        Select Doctor or Scan
                      </span>
                    </BasicMenuItem>
                    {Array.isArray(users) && users.length > 0 ? (
                      users.map((val, i) => (
                        <BasicMenuItem key={i} value={val.user_uid}>
                          {val.full_name}
                        </BasicMenuItem>
                      ))
                    ) : (
                      <BasicMenuItem disabled>
                        No Doctors Available
                      </BasicMenuItem>
                    )}
                  </BasicSelect>
                </Box>
                {userType == "SCAN" && (
                  <BasicSelect
                    value={selectedDuration || "default"}
                    onChange={(e) => setSelectedDuration(e.target.value)}
                    sx={{
                      textAlign: "left",
                      width: "220px",
                      marginTop: "20px",
                    }}
                  >
                    <BasicMenuItem value="default" style={{ display: "none" }}>
                      <span style={{ color: "#bbb7b7" }}>Select Duration</span>
                    </BasicMenuItem>
                    {lineProgressLoading ? (
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress />
                      </Box>
                    ) : Array.isArray(slots) && slots.length > 0 ? (
                      slots.map((val, i) => (
                        <BasicMenuItem key={i} value={val}>
                          {parseScanDuration(val)}
                        </BasicMenuItem>
                      ))
                    ) : (
                      <BasicMenuItem disabled>
                        No Doctors Available
                      </BasicMenuItem>
                    )}
                  </BasicSelect>
                )}
              </>
            )}

            {generated ? (
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <ContainedButton
                  sx={{
                    backgroundColor: "#243665",
                    "&:hover": {
                      backgroundColor: "#243665",
                    },
                    width: "85px",
                    mr: 2,
                  }}
                  onClick={navigatePrevious}
                >
                  Back
                </ContainedButton>
                <ContainedButton
                  sx={{
                    width: "85px",
                  }}
                  onClick={handleDownload}
                >
                  Download
                </ContainedButton>
              </Box>
            ) : (
              <Box sx={{ textAlign: "center", marginTop: "30px" }}>
                <ContainedButton
                  disabled={
                    !selectedUser || (userType == "SCAN" && !selectedDuration)
                  }
                  onClick={generateQR}
                >
                  Generate QR Code
                </ContainedButton>
              </Box>
            )}
          </Box>
        </>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100000 }}
        open={loaderOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
